import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateUserAlgConfidenceSettings } from "../../../../../actions";
import {
  Typography,
  Box,
  Slider,
  Backdrop,
  Modal,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import HeatMap from "./HeatMap";
import { useWebServer } from "../../../../../providers";
import { findLatestVersion } from "../../../../../utils/helpers";
import DevicesTable from "./DevicesTable";
import CloseIcon from "@mui/icons-material/Close";
import ResponsiveButton from "../../../../Forms/ResponsiveButton";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // background: '#f2f2f2',
  border: "2px solid #000",
  boxShadow: 24,
  height: "calc(100vh - 100px)",
  width: "calc(100vw - 100px)",
  p: 4,
};

const heatmapContainerStyle = {
  overflow: "auto", // Enable scrolling
  maxHeight: "calc(100vh - 100px)", // Maximum height of the modal content
  maxWidth: "calc(100vw - 100px)", // Maximum width of the modal content
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const AutofillSettingDisplayText = ({
  algVersion,
  width = 1000,
  height = 1000,
  userAlgConfidence,
  updateUserAlgConfidenceSettings,
  latestAlgVersion,
  uniqueVectorTypes,
  devicesVectorTypes,
  onClose,
}) => {
  const { sendRequest } = useWebServer();
  const [data, setData] = useState([]);
  const [percMarks, setPercMarks] = useState([]);
  const [specColumn, setSpecColumn] = useState([]);
  const [matrix, setMatrix] = useState([]);
  const [algConfidence, setAlgConfidence] = useState(userAlgConfidence);
  const [lastFetchAlgConfidence, setLastFetchAlgConfidence] = useState(-2);
  const [vectorType, setVectorType] = useState(uniqueVectorTypes[0]);
  const [previousSave, setPreviousSave] = useState(userAlgConfidence);
  const [regions, setRegions] = useState([]);
  const [hideNonRegion, setHideNonRegion] = useState(true);
  const [isAlgConfidenceDirty, setIsAlgConfidenceDirty] = useState(false);
  useEffect(() => {
    setAlgConfidence(userAlgConfidence);
  }, [userAlgConfidence]);

  useEffect(() => {
    Promise.all([getData(-1), getSpecColumn()]).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vectorType, latestAlgVersion]);

  const saveAlgConfidence = async (algConfidence) => {
    await sendRequest("patch")({
      url: `/user/user-algConfidence`,
      devPort: 5004,
      data: { algConfidence: algConfidence },
    });
    updateUserAlgConfidenceSettings(algConfidence);
    setPreviousSave(algConfidence);
    setIsAlgConfidenceDirty(false);
  };

  const resetAlgConfidence = () => {
    setAlgConfidence(previousSave);
    setIsAlgConfidenceDirty(false);
  };

  const defaultAlgConfidence = () => {
    saveAlgConfidence(vectorType?.startsWith('mos') ? 60 : 55).then()
    setAlgConfidence(vectorType?.startsWith('mos') ? 60 : 55)
  };

  const onChangeAlgConfidence = (e) => {
    let num = e.target.value;
    if (e.target.value !== algConfidence) {
      let res = percMarks.reduce(
        (nl, curr) => (curr.value <= num && curr.value > nl ? curr.value : nl),
        0
      );
      if (res !== lastFetchAlgConfidence) {
        getData(res).then();
        setLastFetchAlgConfidence(res);
      }
    }
    setAlgConfidence(num);
  };

  const getSpecColumn = async () => {
    let res = await sendRequest("get")({
      url: `/algorithm/species/${vectorType}/${latestAlgVersion}`,
      isOrganizationAPI: true,
    });
    if (res?.data || {}) {
      if (res?.data?.region) {
        setRegions([...Object.values(res?.data?.region), "unknown"]);
      } else {
        setRegions([]);
      }
      let specName = []
      for (let cur in res.data?.classes) {
        specName.push({ id: cur, name: res.data?.classes[cur] })
      }
      setSpecColumn([...(specName.sort((a, b) => {
        return a?.id - b?.id
      }).map((cur) => cur.name)), "unknown"]);
    }
  };

  const getData = async (targetPerc) => {
    if (!vectorType && !latestAlgVersion) return;

    let algType = vectorType?.startsWith("mo") ? "mosquito" : "tick";
    let res = await sendRequest("get")({
      url: `/algorithm/confusionMatrix/${algType}/${latestAlgVersion}/${targetPerc}`,
      isOrganizationAPI: true,
    });
    const { normalizedMatrix, numericArray } = res.data;
    if (normalizedMatrix) {
      setMatrix(normalizedMatrix);
      setPercMarks(
        numericArray.map((d) => {
          return { value: Number.parseInt(d), label: d };
        })
      );
    }
  };

  useEffect(() => {
    if (specColumn.length === 0) return;

    let processedData = [];
    matrix.forEach((d, x) => {
      d.forEach((cur, y) => {
        processedData.push({
          y: specColumn[x],
          x: specColumn[y],
          value: Number.parseFloat(cur),
        });
      });
    });
    setData(processedData);
  }, [specColumn, matrix]);

  return (
    <Box
      style={modalStyle}
      className={" bg-white rounded-xl border-4 border-transparent"}
    >
      <ResponsiveButton
        sx={{
          color: "status.info",
          position: "absolute",
          top: "1.5rem",
          left: "80%",
        }}
        variant="contained"
        style={{
          color: "#2196F3",
          backgroundColor: "white",
          borderColor: "rgba(63, 81, 181, 0.5)",
          border: "1px solid",
          boxShadow: "none",
        }}
        onClick={onClose}
      >
        <CloseIcon
          sx={{
            fontSize: ["1.5rem", "1.5rem", "1.5rem", "1.5rem", "2rem", "2rem"],
          }}
        />
      </ResponsiveButton>
      <Box style={heatmapContainerStyle}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
            mb: "0.5rem",
            mt: "1rem",
          }}
        >
          Algorithm Settings
        </Typography>

        <Box sx={{ minWidth: 120, display: "inline-block" }}>
          <FormControl size="small">
            <InputLabel id="demo-simple-select-label">Algorithm</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={vectorType}
              // label="Algorithm"
              onChange={(e) => setVectorType(e.target.value)}
            >
              {uniqueVectorTypes.map((d, i) => (
                <MenuItem value={d}>{d}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography
            sx={{
              height: "40px",
              lineHeight: "40px",
              display: "inline-block",
              fontWeight: 300,
              fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
            }}
          >
            {" "}
            --- {latestAlgVersion}
          </Typography>
        </Box>
        <Box sx={{ width: 700, mt: ".5rem" }}>
          <Typography
            sx={{ display: "inline-block", fontWeight: 300, float: "left" }}
          >
            Algorithm Confidence Threshold:
          </Typography>
          {isAlgConfidenceDirty ? (
            <>
              <Button
                sx={{ display: "inline-block", float: "right" }}
                size="medium"
                variant="outlined"
                onClick={(e) => saveAlgConfidence(algConfidence)}
              >
                Save
              </Button>
              <Button
                sx={{ display: "inline-block", float: "right", mr: ".5rem" }}
                size="medium"
                variant="contained"
                style={{ backgroundColor: "#E10050" }}
                onClick={(e) => resetAlgConfidence()}
              >
                Restore
              </Button>
              <Button
                sx={{ display: "inline-block", float: "right", mr: ".5rem" }}
                size="medium"
                variant="outlined"
                onClick={(e) => defaultAlgConfidence()}
              >
                {`Default conf(${vectorType?.startsWith('mos') ? 60 : 55})`}
              </Button>
            </>
          ) : null}
        </Box>
        {percMarks && percMarks.length > 0 && (
          <Box sx={{ width: 700 }}>
            <Slider
              valueLabelDisplay="auto"
              value={algConfidence}
              step={1}
              marks={percMarks}
              onChange={(e) => onChangeAlgConfidence(e)}
              onChangeCommitted={(e) => {
                setIsAlgConfidenceDirty(previousSave !== algConfidence);
              }}
            />
          </Box>
        )}
        <Box sx={{ width: 700, display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
          {
            regions?.length > 1 &&
            (

              <Button
                sx={{ display: "inline-block", float: "right" }}
                size="medium"
                variant="outlined"
                onClick={() => setHideNonRegion(!hideNonRegion)}
              >
                {!hideNonRegion ? "Hide" : "Show all"}
              </Button>

            )}
        </Box>


        <div style={{ position: "relative" }}>
          <HeatMap
            data={data}
            height={height}
            width={width}
            regions={regions}
            hideNonRegion={hideNonRegion}
          ></HeatMap>
        </div>
        <DevicesTable devicesVectorTypes={devicesVectorTypes} />
      </Box>
    </Box>
  );
};

const AlgorithmSettingModal = ({
  autofillSettings,
  algorithmSettingModalOpen,
  setAlgorithmSettingModalOpen,
  updateUserAutofillSettings,
  device,
  vectorType,
  algVersion,
  userAlgConfidence,
  updateUserAlgConfidenceSettings,
  latestAlgVersion,
  uniqueVectorTypes,
  devicesVectorTypes,
}) => {
  const onClose = () => setAlgorithmSettingModalOpen(false);

  return (
    <div>
      <Modal
        open={algorithmSettingModalOpen}
        onClose={onClose}
        BackdropComponent={Backdrop}
      >
        <AutofillSettingDisplayText
          updateUserAutofillSettings={updateUserAutofillSettings}
          autofillSettings={autofillSettings}
          onClose={onClose}
          device={device}
          vectorType={vectorType}
          algVersion={algVersion}
          userAlgConfidence={userAlgConfidence}
          updateUserAlgConfidenceSettings={updateUserAlgConfidenceSettings}
          latestAlgVersion={latestAlgVersion}
          uniqueVectorTypes={uniqueVectorTypes}
          devicesVectorTypes={devicesVectorTypes}
        />
      </Modal>
    </div>
  );
};

export const mapStateToProps = (state) => {
  const { autofillSettings } = state.user;

  let selectedDevice = state.devices?.selectedDevice || "";
  let device = state.devices?.[selectedDevice];

  const algVersions = Object.values(state.devices).reduce((pre, cur) => {
    if (cur?.algVersion) {
      return [...pre, cur?.algVersion];
    }
    return pre;
  }, []);
  const vectorTypes = Object.values(state.devices).reduce((pre, cur) => {
    if (cur?.vectorType) {
      return [...pre, cur?.vectorType];
    }
    return pre;
  }, []);

  let devicesVectorTypes = [];
  Object.values(state.devices).forEach((d) => {
    const { displayName, vectorType, algVersion, env } = d;
    if (env === process.env.REACT_APP_ENV.toUpperCase()) {
      devicesVectorTypes.push({ displayName, vectorType, algVersion });
    }
  });

  const uniqueVectorTypes = [...new Set(vectorTypes)];

  //for testing
  //const uniqueVectorTypes = ['mozus', 'mozafrica', 'mosquito'];

  return {
    autofillSettings,
    vectorType: uniqueVectorTypes[1],
    algVersion: device?.algVersion || "v2.1.1",
    userAlgConfidence:
      state.user?.algConfidence || state.user?.algConfidence === 0
        ? state.user.algConfidence
        : 0,
    uniqueVectorTypes,
    latestAlgVersion: findLatestVersion(algVersions),
    devicesVectorTypes,
  };
};

export default connect(mapStateToProps, { updateUserAlgConfidenceSettings })(
  AlgorithmSettingModal
);
