import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Button, Typography, Tooltip } from "@mui/material";
import { Field } from "redux-form";
import {
  renderInput,
  renderAutocomplete,
} from "../../../../../Forms/FormFields";
import ResponsiveButton from "../../../../../Forms/ResponsiveButton";
import MapIcon from "@mui/icons-material/Map";
import { checkCommaAndBackSlash } from "../../../../../../lib/validationHelper";

const TrapSiteForm = ({
  searchAddress,
  handleSubmit,
  onSubmit,
  onDiscardChanges,
  change,
  addressOptions,
  trapSitePlacementMode,
  setTrapSitePlacementMode,
}) => {
  const [address, setAddress] = useState();

  const handleUpdateGPSLocation = useCallback(async () => {
    if (address) {
      const newGPS = await searchAddress(address);
      change("gpsLocation", newGPS);
    }
  }, [address, change, searchAddress]);

  useEffect(() => {
    const timeOutId = setTimeout(() => handleUpdateGPSLocation(), 250);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const onChangeAddress = (value) => {
    //console.log(`changing address to ${value}`);
    setAddress(value);
    change("address", value);
  };

  const autofillGpsLocation = (gpsLocation) => {
    let newArr = gpsLocation.replace(",", "").trim().split(" ");
    let res;
    if (newArr.length === 1) {
      res = newArr.join(" ") + " ";
    } else {
      res = newArr.join(" ");
    }
    change("gpsLocation", res);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        className={"flex flex-col p-6"}
        sx={{
          "& .MuiFormControl-root": {
            width: "100%",
          },
        }}
      >
        <Field
          label="Name"
          name="displayName"
          inputProps={{
            id: "display-name",
          }}
          component={renderInput}
          validate={[checkCommaAndBackSlash]}
        />
        <Field
          style={{ marginTop: "1rem" }}
          label="Description"
          inputProps={{
            id: "description",
          }}
          name="description"
          component={renderInput}
        />

        <Field
          label="Address"
          name="address"
          inputProps={{
            id: "address",
          }}
          disabled={false}
          hideFormErrors={false}
          options={addressOptions}
          fieldValue={address}
          onChange={(e, value) => onChangeAddress(value)}
          component={renderAutocomplete}
          multilineFlag={true}
          filterOptions={(arr, state) => {
            return arr;
          }}
        />
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          style={{ marginTop: "1rem" }}
        >
          <Grid item xs={8}>
            <Field
              label="GPS Location"
              inputProps={{
                id: "gps-location",
              }}
              name="gpsLocation"
              component={renderInput}
              onBlur={(e) => {
                e.preventDefault();
                autofillGpsLocation(e.target.value);
              }}
              // disabled={loadingAddress}
              // onChange={e => setGPSLocation(e.target.value)}
            />
          </Grid>

          <Grid item>
            <Typography sx={{ textAlign: "center", display: "inline-block" }}>
              {" "}
              or{" "}
            </Typography>
            <Tooltip
              title={
                <Typography variant="subtitle1">
                  Click to enable trapsite placement mode on map
                </Typography>
              }
            >
              <Button onClick={() => setTrapSitePlacementMode(true)}>
                <MapIcon
                  color={trapSitePlacementMode ? "disabled" : "primary"}
                />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>

        <Box
          sx={{
            mt: ["0.75rem", "0.75rem", "0.75rem", "0.75rem", "1rem", "1rem"],
            mx: "auto",
            "& .MuiButton-root": {
              mx: "2rem",
            },
          }}
        >
          <ResponsiveButton id={"save-trap-site"} color="primary" type="submit">
            Save
          </ResponsiveButton>
          <ResponsiveButton
            id={"discard-trap-site-changes"}
            style={{ color: "#E10050" }}
            onClick={onDiscardChanges}
          >
            Cancel
          </ResponsiveButton>
        </Box>
      </Box>
    </form>
  );
};

export default TrapSiteForm;
