import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import {
  Box,
  Typography,
  Tooltip,
  CircularProgress,
  Grid,
  Button,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  genusSpeciesAutoCompleteOptions,
  gonotrophicAutoCompleteOptions,
  lifeStatusAutoCompleteOptions,
} from "../../../../../utils/const";
import {
  renderAutocomplete,
  RenderTypography,
} from "../../../../Forms/FormFields";
import { getSppDisplayName } from "../../../../../utils/caseConvert";
import AutofillSettingModal from "./AutoFillSettingModal";
import ResponsiveButton from "../../../../Forms/ResponsiveButton";
import ResponsiveIconButton from "../../../../Forms/ResponsiveIconButton";
import { RenderAutocompleteMultiple } from "../../../../Forms/renderAutocompleteMultiple";
import ImageContainer from "./ImageContainer";
import AutoSave from "./AutoSave";
import SexInput from "./Components/SexInput";
import {
  checkCommaAndBackSlash,
  checkCommaAndBackSlashOnTags,
  checkWellNumber,
} from "../../../../../lib/validationHelper";
import HiddenField from "./Components/HiddenField";
import _ from "lodash";
import { capitalize } from "../../../../../utils/caseConvert";

const buttonStyles = {
  autofillButton: {
    color: "status.info",
    borderColor: "status.info",
  },
  infoButton: {
    color: "status.info",
  },
};

const specimenSx = {
  fontSize: [".75rem", ".75rem", ".75rem", "0.75rem", "1.125rem", "1.125rem"],
};

const smallerSpecimenSx = {
  fontSize: [".5rem", ".5rem", ".5rem", "0.5rem", "1rem", "1rem"],
};

const margin5rem = [
  "0.5rem !important",
  "0.5rem !important",
  "0.5rem !important",
  "0.5rem !important",
  "0.5rem !important",
  "0.5rem !important",
];

const SpecimenDetails = (props) => {
  const {
    image,
    backImage,
    frontImage,
    trayImage,
    specimenNum,
    lastSpecimenNum,
    handleNextSpecimen,
    handlePreviousSpecimen,
    algGenus,
    algSpecies,
    algSpeciesConfidence,
    algRegional,
    algSex,
    nrAlgGenus,
    nrAlgSpecies,
    nrAlgSpeciesConfidence,
    nrAlgRegional,
    userAlgConfidence,
    specimenID,
    defaultInstitutionId,
    trayId,
    updatingDisplayed,
    // eslint-disable-next-line
    loading,
    hideFormErrors,
    metadata,
    shouldAllowAutofill,
    plateAutoCompleteOptions,
    autofillModalOpen,
    setAutofillModalOpen,
    handleAutofill,
    loadingAlgResults,
    onBlurGenusSpecies,
    onBlurPlate,
    onBlurWell,
    normalizeGenusSpecies,
    normalizeWell,
    wellAutocompleteOptions,
    autofillSettings,
    tagOptions,
    specimenValues,
    saveCurrentChange,
    flipTraySide,
    isTwoSideImaged,
    vectorType,
  } = props;
  const [autofillTooltip, setAutofillTooltip] = useState(false);
  const [highLightSpecies, setHighLightSpecies] = useState(false);
  const [highLightSex, setHighLightSex] = useState(false);
  const [highLightPlateAndWell, setHighLightPlateAndWell] = useState(false);
  const [highLightLifeStage, setHighLightLifeStage] = useState(false);
  const [highLightGonotrophic, setHighLightGonotrophic] = useState(false);

  useEffect(() => {
    setHighLightSpecies(
      autofillTooltip &
      autofillSettings?.speciesChecked &
      !(specimenValues?.genusSpecies?.length > 0)
    );
    setHighLightPlateAndWell(
      autofillTooltip &
      autofillSettings?.plateAndWellChecked &
      !(
        (specimenValues?.plate?.length > 0) &
        (specimenValues?.well?.length > 0)
      )
    );
    setHighLightSex(
      autofillTooltip &
      autofillSettings?.sexChecked &
      !(specimenValues?.sex?.length > 0)
    );
    setHighLightLifeStage(
      autofillTooltip &
      autofillSettings?.lifeStageChecked &
      !(specimenValues?.lifeStage?.length > 0)
    );
    setHighLightGonotrophic(
      autofillTooltip &
      autofillSettings?.gonotrophicChecked &
      !(specimenValues?.gonotrophic?.length > 0)
    );
  }, [autofillTooltip, autofillSettings, specimenValues]);

  const clearToolTip = () => {
    setAutofillTooltip(false);
    setHighLightSpecies(false);
    setHighLightSex(false);
    setHighLightPlateAndWell(false);
    setHighLightLifeStage(false);
    setHighLightGonotrophic(false);
  };

  const save = async (values, difference) => {
    let isSpecimenChanged = difference.id ? true : false;
    //saveCurrentChange({ ...values }, isSpecimenChanged)
    saveCurrentChange(values, difference, isSpecimenChanged);
  };

  const computeUnknownList = () => {
    let unknownList = [];
    const inRegionName = getSppDisplayName(algGenus, algSpecies);
    const inRegionConf = Math.round(algSpeciesConfidence * 100);
    if (inRegionName && inRegionConf) {
      unknownList.push({
        name: inRegionName,
        genus: algGenus,
        species: algSpecies,
        confidence: inRegionConf,
        region:
          algRegional && nrAlgRegional
            ? ""
            : algRegional
              ? " (in region)"
              : " (out of region)",
      });
    }

    if (nrAlgGenus && nrAlgSpecies && nrAlgGenus !== "") {
      const nrName = getSppDisplayName(nrAlgGenus, nrAlgSpecies);
      const nrConf = Math.round(nrAlgSpeciesConfidence * 100);
      if (nrName && nrConf) {
        unknownList.push({
          name: nrName,
          genus: nrAlgGenus,
          species: nrAlgSpecies,
          confidence: nrConf,
          region:
            algRegional && nrAlgRegional
              ? ""
              : nrAlgRegional
                ? " (in region)"
                : " (out of region)",
        });
      }
    }
    return unknownList
      .sort((a, b) => b.confidence - a.confidence)
      .map((cur, index) => {
        return (
          <Typography
            sx={smallerSpecimenSx}
            key={index}
            onClick={(e) => {
              saveCurrentChange(
                { ...specimenValues, genusSpecies: cur.name },
                { genusSpecies: cur.name },
                false
              );
            }}
          >
            <em>{capitalize(cur.name)}</em> - {`${cur.confidence}%`}
            {cur.region}
          </Typography>
        );
      });
  };
  return (
    <Form
      onSubmit={() => { }}
      mutators={{
        setSex: (args, state, utils) => {
          utils.changeValue(state, "sex", () => "");
        },
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      initialValues={specimenValues}
      validate={(values) => {
        const errors = {};
        if (
          values.well &&
          values.well?.length > 0 &&
          (values.plate?.length <= 0 || !values.plate)
        ) {
          errors.plate = "Must have a plate number";
        }
        if (
          values.plate &&
          values.plate?.length > 0 &&
          (values.well?.length <= 0 || !values.well)
        ) {
          errors.well = "Must have a well number";
        }
        return errors;
      }}
      render={(form, change) => (
        <div className={"flex flex-col"} id={"specimen-details"}>
          <AutoSave debounce={300} save={save} form={form} />
          <Box
            className={"flex mx-auto justify-between"}
            sx={{
              width: ["250px", "250px", "250px", "250px", "300px", "300px"],
              marginTop: ["3rem", "3rem", "3rem", "3rem", "1rem", "1rem"],
              maxWidth: "100%",
            }}
          >
            <span id={"specimen-id"} className={" text-blue-400 my-auto"}>
              <Field
                label="Institution Id"
                name={`institutionId`}
                inputProps={{
                  id: `institution-id-field`,
                  "data-testid": "Institution Id",
                }}
                fieldValue={metadata?.[`institutionId`]}
                hideFormErrors={hideFormErrors}
                normalize={normalizeGenusSpecies}
                component={RenderTypography}
                defaultInstitutionId={defaultInstitutionId}
              />
            </span>
            {isTwoSideImaged && (
              <Button size="small" variant="outlined" onClick={flipTraySide}>
                Flip
              </Button>
            )}
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                id={"specimen-position"}
                className={" text-blue-400"}
                sx={{
                  ...specimenSx,
                  textAlign: "center",
                  my: "auto",
                }}
              >
                POS {specimenNum ? specimenNum + 1 : 1}
              </Typography>
            </Box>
          </Box>
          <ImageContainer
            image={image}
            backImage={backImage}
            frontImage={frontImage}
            trayImage={trayImage}
            handleNextSpecimen={() => handleNextSpecimen(form)}
            handlePreviousSpecimen={() => handlePreviousSpecimen(form)}
            specimenID={specimenID}
            trayId={trayId}
            updatingDisplayed={updatingDisplayed}
          />

          <Box
            className={"flex mx-auto justify-between"}
            sx={{
              width: ["250px", "280px", "280px", "280px", "300px", "330px"],
              marginTop: [
                "0.5rem",
                "0.5rem",
                "0.5rem",
                "0.5rem",
                "0.75rem",
                "0.75rem",
              ],
              marginBottom: ["1rem", "1rem", "1rem", "1rem", "1rem", "1rem"],
            }}
          >
            {algSpeciesConfidence || algSpeciesConfidence === 0 ? (
              (algSpeciesConfidence > userAlgConfidence && algRegional) ||
                ["empty", "Empty", "Dirty", "dirty"].includes(algGenus) ? (
                <>
                  <div className={"flex items-center"}>
                    <Typography
                      id={"alg-genus"}
                      className={"italic"}
                      sx={specimenSx}
                      onClick={() => {
                        saveCurrentChange(
                          {
                            ...specimenValues,
                            genusSpecies: getSppDisplayName(
                              algGenus,
                              algSpecies
                            ),
                          },
                          {
                            genusSpecies: getSppDisplayName(
                              algGenus,
                              algSpecies
                            ),
                          },
                          false
                        );
                      }}
                    >
                      {(algGenus || algGenus === "") &&
                        (algSpecies || algSpecies === "")
                        ? getSppDisplayName(algGenus, algSpecies)
                        : null}
                      <br />
                      {["empty", "Empty", "Dirty", "dirty"].includes(algGenus)
                        ? ""
                        : ["male", "female", 'Male', 'Female'].includes(algSex) ?
                          _.capitalize(algSex)
                          : null}
                    </Typography>

                    <Typography sx={smallerSpecimenSx}></Typography>
                    {loadingAlgResults && (
                      <CircularProgress size={"1rem"} className={"ml-2"} />
                    )}
                  </div>
                  <div className={"flex items-center"}>
                    <Typography id={"alg-genus-confidence"} sx={specimenSx}>
                      {isNaN(algSpeciesConfidence) ||
                        ((algGenus || algGenus === "") &&
                          (algSpecies || algSpecies === "") &&
                          (algGenus?.toLowerCase() === "unknown" ||
                            algSpeciesConfidence === 0)) ||
                        ["empty", "Empty", "Dirty", "dirty"].includes(algGenus)
                        ? ""
                        : `${Math.round(algSpeciesConfidence * 100)}%`}
                    </Typography>
                    {loadingAlgResults && (
                      <CircularProgress size={"1rem"} className={"ml-2"} />
                    )}
                  </div>
                </>
              ) : (
                <div>
                  <Typography sx={specimenSx}>Unknown Species</Typography>
                  <Box sx={{ pl: ".5rem" }}>{computeUnknownList()}</Box>
                  {algSex && (algSex === "male" || algSex === "female") ? (
                    <Box className={"ml-2"}>
                      <Typography sx={smallerSpecimenSx}>
                        {algGenus === "Empty" || algGenus === "empty"
                          ? "Sex Unsure"
                          : algSex === "male"
                            ? "Male"
                            : "Female"}
                      </Typography>
                      {/* <img
                        className={"w-4 h-6 display-inline-block "}
                        src={
                          algGenus === "Empty" || algGenus === "empty"
                            ? UnsureSexIcon
                            : algSex === "male"
                              ? MaleSexIcon
                              : FemaleSexIcon
                        }
                        alt=""
                        title={algGenus === "Empty" ? "empty" : algSex}
                      ></img> */}
                    </Box>
                  ) : null}
                </div>
              )
            ) : null}
          </Box>
          <Box
            className={"flex border-b-2 border-black-100 justify-between"}
            style={{ paddingBottom: "8px" }}
            sx={{
              paddingBottom: ["8px", "8px", "8px", "8px", "8px", "8px"],
              mx: ["1rem", "1rem", "1rem", "1rem", "2rem", "2rem"],
            }}
          >
            <Typography
              component="span"
              style={{ alignSelf: "center" }}
              sx={{
                fontWeight: 500,
                fontSize: [
                  ".75rem",
                  ".75rem",
                  ".75rem",
                  ".75rem",
                  "1.15rem",
                  "1.25rem",
                ],
              }}
            >
              Contributed Data
            </Typography>
            <div>
              <Tooltip
                title={
                  <div>
                    <Typography variant="subtitle1">
                      {!shouldAllowAutofill?.autofillSettingsFilled &&
                        "Update autofill settings before autofilling\n"}
                    </Typography>
                    <Typography variant="subtitle1">
                      {!shouldAllowAutofill?.metadataFilled &&
                        shouldAllowAutofill?.autofillSettingsFilled &&
                        "Enter data for current specimen before autofilling or filling with empty data\n"}
                    </Typography>
                    <Typography variant="subtitle1">
                      {shouldAllowAutofill.metadataFilled &&
                        shouldAllowAutofill.autofillSettingsFilled &&
                        "Enter data for at least one of the selected fields before autofilling or filling with empty data\n"}
                    </Typography>
                  </div>
                }
                onOpen={() => setAutofillTooltip(true)}
                open={
                  !shouldAllowAutofill?.shouldAllowAutofill && autofillTooltip
                }
                onClose={() => clearToolTip()}
              >
                <span>
                  <ResponsiveButton
                    className={"autofill-setting"}
                    // className={classes.autofillButton}
                    sx={buttonStyles.autofillButton}
                    // disabled={!shouldAllowAutofill?.shouldAllowAutofill}
                    variant={"outlined"}
                    onClick={() => handleAutofill(form)}
                  >
                    Autofill{" "}
                    {specimenNum
                      ? `${specimenNum + 1}-${lastSpecimenNum + 1}`
                      : `1-12`}
                  </ResponsiveButton>
                </span>
              </Tooltip>
              <Tooltip
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={<Typography>&darr;</Typography>}
                placement="top"
                open={
                  !shouldAllowAutofill?.shouldAllowAutofill &&
                  autofillTooltip &&
                  !shouldAllowAutofill?.autofillSettingsFilled
                }
              >
                <ResponsiveIconButton>
                  <SettingsIcon
                    // className={classes.infoButton}
                    sx={buttonStyles.infoButton}
                    onClick={() => setAutofillModalOpen(true)}
                  />
                </ResponsiveIconButton>
              </Tooltip>
              <AutofillSettingModal
                autofillModalOpen={autofillModalOpen}
                setAutofillModalOpen={setAutofillModalOpen}
              />
            </div>
          </Box>
          <form className={"flex flex-col"} id={"specimen-details-form"}>
            <Grid
              container
              sx={{
                px: ["1rem", "1rem", "1rem", "1rem", "2rem", "2rem"],
                pt: ["0.5rem", "0.5rem", "0.5rem", "0.5rem", "1rem", "1rem"],
              }}
              spacing={2}
            >
              <Grid
                item
                xs={6}
                sx={{
                  display: autofillSettings?.speciesDisplay ? "flex" : "none",
                  backgroundColor: highLightSpecies
                    ? "rgba(99, 179, 237, 0.25)"
                    : "",
                }}
                data-testid="SpeciesBox"
              >
                <Field
                  label="Species"
                  name={`genusSpecies`}
                  inputProps={{
                    id: `genus-species`,
                  }}
                  hideFormErrors={hideFormErrors}
                  normalize={normalizeGenusSpecies}
                  component={renderAutocomplete}
                  options={
                    (vectorType || "").startsWith("ti")
                      ? genusSpeciesAutoCompleteOptions?.["tick"]
                      : (vectorType || "").startsWith("mo")
                        ? genusSpeciesAutoCompleteOptions?.["mosquito"]
                        : [
                          ...genusSpeciesAutoCompleteOptions?.["mosquito"],
                          ...genusSpeciesAutoCompleteOptions?.["tick"],
                        ]
                  }
                  autocompleteprops={{
                    getOptionLabel: (option) => option,
                    isOptionEqualToValue: (option, value) =>
                      option.toLowerCase() === value?.toLowerCase(),
                  }}
                  // onChange={(event, value) => setGenusSpecies(value)}
                  onBlur={(event, value) => onBlurGenusSpecies(value, form)}
                  helperText="Taxonomist determined ID"
                  multilineFlag={true}
                  validate={checkCommaAndBackSlash}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: autofillSettings?.sexDisplay ? "flex" : "none",
                  backgroundColor: highLightSex
                    ? "rgba(99, 179, 237, 0.25)"
                    : "",
                }}
                data-testid="SexBox"
              >
                <SexInput
                  hideFormErrors={hideFormErrors}
                  form={form}
                  change={change}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  mt: margin5rem,
                  mb: margin5rem,
                  display: autofillSettings?.lifeStageDisplay ? "flex" : "none",
                  backgroundColor: highLightLifeStage
                    ? "rgba(99, 179, 237, 0.25)"
                    : "",
                }}
              >
                <Field
                  label="Life Stage"
                  name={`lifeStage`}
                  inputProps={{ id: `life-stage-field` }}
                  component={renderAutocomplete}
                  options={lifeStatusAutoCompleteOptions}
                  helperText="Life Stage"
                  validate={checkCommaAndBackSlash}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  mt: margin5rem,
                  mb: margin5rem,
                  display: autofillSettings?.gonotrophicDisplay
                    ? "flex"
                    : "none",
                  backgroundColor: highLightGonotrophic
                    ? "rgba(99, 179, 237, 0.25)"
                    : "",
                }}
              >
                <Field
                  label="Gonotrophic"
                  name={`gonotrophic`}
                  inputProps={{ id: `gonotrophic-field` }}
                  component={renderAutocomplete}
                  options={gonotrophicAutoCompleteOptions}
                  helperText="Feeding Status"
                  validate={checkCommaAndBackSlash}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  mt: margin5rem,
                  mb: margin5rem,
                  display: autofillSettings?.molecularDisplay ? "flex" : "none",
                  backgroundColor: highLightPlateAndWell
                    ? "rgba(99, 179, 237, 0.25)"
                    : "",
                }}
              >
                <Field
                  label="Unique Plate Number"
                  name={`plate`}
                  inputProps={{ id: `plate-field` }}
                  hideFormErrors={hideFormErrors}
                  component={renderAutocomplete}
                  options={plateAutoCompleteOptions}
                  onBlur={(event) => onBlurPlate(event.target.value, form)}
                  helperText="Molecular ID Plate"
                  validate={checkCommaAndBackSlash}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  mt: margin5rem,
                  mb: margin5rem,
                  display: autofillSettings?.molecularDisplay ? "flex" : "none",
                  backgroundColor: highLightPlateAndWell
                    ? "rgba(99, 179, 237, 0.25)"
                    : "",
                }}
              >
                <Field
                  label="Well Number"
                  name={`well`}
                  inputProps={{ id: `well-field` }}
                  normalize={normalizeWell}
                  hideFormErrors={hideFormErrors}
                  component={renderAutocomplete}
                  options={wellAutocompleteOptions}
                  onBlur={(event, value) => {
                    onBlurWell(event.target.value, form);
                  }}
                  helperText="Molecular ID Well( A1 - H12)"
                  validate={checkWellNumber}
                />
              </Grid>
              <Grid
                item
                sx={{ mt: margin5rem }}
                data-testid="SpecimenTagList"
                xs={12}
              >
                <Field
                  multi={"true"}
                  label="Specimen Tags"
                  name={`tags`}
                  inputProps={{ id: `tags` }}
                  hideFormErrors={hideFormErrors}
                  component={RenderAutocompleteMultiple}
                  fieldValue={form?.values?.tags || []}
                  options={tagOptions || []}
                  onChange={(event, value) => {
                    form.form.mutators.setValue("tags", [...value]);
                  }}
                  onBlur={(e) => e.preventDefault()}
                  multiple={true}
                  validate={checkCommaAndBackSlashOnTags}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: margin5rem }}>
                <HiddenField
                  text={_.capitalize(
                    specimenValues?.genusSpecies?.toLowerCase()
                  )}
                  Name="Species: "
                  display={
                    specimenValues?.genusSpecies?.length > 0 &&
                    !autofillSettings?.speciesDisplay
                  }
                  isItalic={true}
                />
                <HiddenField
                  text={specimenValues?.sex}
                  Name="Sex: "
                  display={
                    specimenValues?.sex?.length > 0 &&
                    !autofillSettings?.sexDisplay
                  }
                />
                <HiddenField
                  text={specimenValues?.lifeStage}
                  Name="Life Stage: "
                  display={
                    specimenValues?.lifeStage?.length > 0 &&
                    !autofillSettings?.lifeStageDisplay
                  }
                />
                <HiddenField
                  text={specimenValues?.gonotrophic}
                  Name="Gonotrophic: "
                  display={
                    specimenValues?.gonotrophic?.length > 0 &&
                    !autofillSettings?.gonotrophicDisplay
                  }
                />
                <HiddenField
                  text={specimenValues?.plate}
                  Name="Plate: "
                  display={
                    !autofillSettings?.molecularDisplay &&
                    specimenValues?.plate &&
                    specimenValues?.well
                  }
                />
                <HiddenField
                  text={specimenValues?.well}
                  Name="Well: "
                  display={
                    !autofillSettings?.molecularDisplay &&
                    specimenValues?.plate &&
                    specimenValues?.well
                  }
                />
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    ></Form>
  );
};

export default SpecimenDetails;
